import React from 'react';
import {
    Route,
    Routes,
} from "react-router-dom";

import './App.css';
import Header from './components/Header'
import HomePage from "./pages/HomePage"
import Footer from "./components/Footer";

function App() {
  return (
      <div className="App text-white min-vh-100">
          <Header/>
          <div>
              <Routes>
                  <Route path="/" element={<HomePage/>}/>
              </Routes>
          </div>
          <Footer/>
      </div>
  );
}

export default App;
