import React from 'react'
import "../styles/Footer.scss"
import { SocialIcon } from "react-social-icons"

function Footer() {

    return (
        <footer className="footer-container">
            <div className="footer-content">
                {/* 첫 번째 컬럼 */}
                <div className="footer-column">
                    <h1>JoyfulTurtles Co., LTD.</h1>
                    <h3>사업문의 | contact@joyfulturtles.com</h3>
                    <h3>경기도 성남시 분당구 대왕판교로 645번길12 경기창조경제혁신센터 9층 A01호</h3>
                    <h3>&copy; JOYFULTURTLES ALL RIGHTS RESERVED.</h3>
                </div>

                {/* 두 번째 컬럼 */}
                <div className="footer-column">
                    <h1 className="footer-heading">Follow Us</h1>
                    <div className="footer-social-icons">
                        <SocialIcon url="https://facebook.com" className="https://aiblurtle.blogspot.com/" style={{ height: 64, width: 64 }} />
                        <SocialIcon url="https://blog.naver.com/blurtle" className="footer-social-link" style={{ height: 64, width: 64 }} />
                        <SocialIcon url="https://www.youtube.com/@Blurtle-nw3oh" className="footer-social-link" style={{ height: 64, width: 64 }} />
                    </div>
                </div>

            </div>
        </footer>
    );
}

export default Footer;