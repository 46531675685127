import React from "react";
import {Button} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import "../styles/HomePage.scss"
import "../styles/TeamMembers.scss"
import {isMobile} from "react-device-detect";

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function Section1() {
    const { t } = useTranslation("Home");
    return (
        <section className="section">
            <div className="title">
                <h1 style={{fontSize: "64px"}}>
                    { t("Section1.1" as any) }
                </h1>
                <br/>
                <h1>{ t("Section1.2" as any) }</h1>
                <h1>{ t("Section1.3" as any) }</h1>
            </div>
        </section>
    );
}

function Section2() {
    const { t } = useTranslation("Home");

    return(
        <section
            id="solutions-section"
            style={{
                backgroundColor: "#121212",
                alignContent: "center",
                minHeight: "1080px",
                padding: "100px 0 100px 0",
                width: "100vw",}}>
            <div className="solution-title">
                <h1>{ t("Section2.solution_title.1" as any) }</h1>
                <h1>{ t("Section2.solution_title.2" as any) }</h1>
            </div>
            <br/>
            <div>
                <div className="flex-container"
                     style={{display: "flex", justifyContent: "center", width: "100%"}}>
                    <img className="responsive-image" src="/images/img.png"
                         alt="Accelerization"
                         style={{flex: "0 0 auto"}}/>
                    <div className="text-container">
                        <h1 style={{color: "#ffe866", fontWeight: "bold", fontSize: "28px", textAlign: "start"}}>
                            { t("Section2.solutions.0.title" as any) }
                        </h1>
                        <ul
                            style={{
                                textAlign: "start",
                                listStyleType: "none",
                                padding: "0",
                                fontSize: "24px"
                            }}>
                            <li>{t("Section2.solutions.0.desc.0" as any)}</li>
                            <li>{t("Section2.solutions.0.desc.1" as any)}</li>
                            <li>{t("Section2.solutions.0.desc.2" as any)}</li>
                            <li>{t("Section2.solutions.0.desc.3" as any)}</li>
                        </ul>
                    </div>
                </div>
                <div className={isMobile ? "flex-container-reverse" : "flex-container"} style={{display: "flex", justifyContent: "center", width: "100%"}}>
                    <div className="text-container">
                        <h1 style={{color: "#ffe866", fontWeight: "bold", fontSize: "28px", textAlign: "start"}}>
                            { t("Section2.solutions.1.title" as any) }
                        </h1>
                        <ul
                            style={{
                                textAlign: "start",
                                listStyleType: "none",
                                padding: "0",
                                fontSize: "24px"
                            }}>
                            <li>{ t("Section2.solutions.1.desc.0" as any) }</li>
                            <li>{ t("Section2.solutions.1.desc.1" as any) }</li>
                            <li>{ t("Section2.solutions.1.desc.2" as any) }</li>
                            <li>{ t("Section2.solutions.1.desc.3" as any) }</li>
                        </ul>
                    </div>
                    <img className="responsive-image" src="/images/img_2.png" alt="Optimization" style={{flex: "0 0 auto"}}/>
                </div>
                <div className="flex-container" style={{display: "flex", justifyContent: "center", width: "100%"}}>
                    <img className="responsive-image"
                         src="/images/img_1.png" alt="Custom AI Solution"
                         style={{flex: "0 0 auto"}}/>
                    <div className="text-container">
                        <h1 style={{color: "#ffe866", fontWeight: "bold", fontSize: "28px", textAlign: "start"}}>
                            { t("Section2.solutions.2.title" as any) }
                        </h1>
                        <ul style={{textAlign: "start", listStyleType: "none", padding: "0", fontSize: "24px"}}>
                            <li>{ t("Section2.solutions.2.desc.0" as any) }</li>
                            <li>{ t("Section2.solutions.2.desc.1" as any) }</li>
                            <li>{ t("Section2.solutions.2.desc.2" as any) }</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

function Section3() {
    const { t } = useTranslation("Home");

    const handleButtonClick = () => {
        window.location.href = 'https://blurtle.ai'; // 이동하고자 하는 URL
    };

    return (
        <section
            id="products-section"
            style={{
            minHeight: "1080px",
            backgroundColor: "#151515",
            // backgroundImage: 'url(/images/main_fliped.jpg)',
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            width: "100vw",
            padding: "100px 0 100px 0",
        }}>
            <div className="solution-title">
                <h1
                    style={{color: "#FFFFFF", fontWeight: "bold", fontSize: "44px"}}>
                    { t("Section3.title" as any) }
                </h1>
            </div>
            <div>
                <h4 style={{color: "#FFE866FF", fontWeight: "bold", fontSize: "36px", margin: "0 20px"}}>
                    { t("Section3.subtitle" as any) }
                </h4>
                <br/>
                <img src="/images/blurtle.png"
                     alt="blurtle"
                     style={{
                         width: isMobile ? "90%" : '50%',
                         height: 'auto', borderRadius: '1%'}}/>
                <br/>
                <br/>
                <Button
                    onClick={handleButtonClick}
                    style={{
                        margin:"50px",
                        padding: "20px",
                        borderRadius: "10px",
                        borderColor: "transparent",
                        backgroundColor: "#10cc52",
                        fontSize: "28px"}}>
                    {t("Section3.blurtle_link" as any)}
                </Button>
                <div style={{margin: "auto", fontSize: "28px", padding: isMobile ? "20px" : 0}}>
                    <p>{t("Section3.paragraph.0" as any)}</p>
                    <p>{t("Section3.paragraph.1" as any)}</p>
                    <p>{t("Section3.paragraph.2" as any)}</p>
                    <br/>
                    <p>{t("Section3.paragraph.3" as any)}</p>
                    <p>{t("Section3.paragraph.4" as any)}</p>
                    <p>{t("Section3.paragraph.5" as any)}</p>
                </div>
            </div>
        </section>
    );
}

const ResponsiveTimeline: React.FC = () => {
    const { t } = useTranslation("Home");

    return (
        <VerticalTimeline>
            <VerticalTimelineElement
                contentStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                contentArrowStyle={{borderRight: '7px solid  rgb(33, 150, 243)'}}
                date="2024 - 현재"
                iconStyle={{background: 'rgb(3,112,198)', color: '#fff'}}>
                <h3 className="vertical-timeline-element-title">{t("Section4.history.0.title" as any)}</h3>
                <h4 className="vertical-timeline-element-subtitle">{t("Section4.history.0.subtitle" as any)}</h4>
                <h4 className="vertical-timeline-element-subtitle">{t("Section4.history.0.subtitle2" as any)}</h4>

            </VerticalTimelineElement>
            <VerticalTimelineElement
                contentStyle={{ background: 'rgb(33,33,33)', color: '#fff' }}
                date="2024.04"
                iconStyle={{ background: 'rgb(255,232,102)', color: '#fff' }}
            >
                <h3 className="vertical-timeline-element-title">{t("Section4.history.1.title" as any)}</h3>
                <h4 className="vertical-timeline-element-subtitle">{t("Section4.history.1.subtitle" as any)}</h4>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                contentStyle={{background: 'rgb(33,33,33)', color: '#fff'}}
                date="2023.12"
                iconStyle={{background: 'rgb(255,232,102)', color: '#fff'}}
            >
                <h3 className="vertical-timeline-element-title">{t("Section4.history.2.title" as any)}</h3>
                <h3 className="vertical-timeline-element-title">{t("Section4.history.2.title2" as any)}</h3>

            </VerticalTimelineElement>
            <VerticalTimelineElement
                contentStyle={{background: 'rgb(33,33,33)', color: '#fff' }}
                date="2023.09"
                iconStyle={{ background: 'rgb(255,232,102)', color: '#fff' }}
            >
                <h3 className="vertical-timeline-element-title">{t("Section4.history.3.title" as any)}</h3>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                contentStyle={{ background: 'rgb(33,33,33)', color: '#fff' }}
                date="2023.07"
                iconStyle={{ background: 'rgb(255,232,102)', color: '#fff' }}
            >
                <h3 className="vertical-timeline-element-title">{t("Section4.history.4.title" as any)}</h3>

            </VerticalTimelineElement>
            <VerticalTimelineElement
                contentStyle={{ background: 'rgb(33,33,33)', color: '#fff' }}
                date="2022.09"
                iconStyle={{ background: 'rgb(255,232,102)', color: '#fff' }}
            >
                <h3 className="vertical-timeline-element-title">{t("Section4.history.5.title" as any)}</h3>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                contentStyle={{ background: 'rgb(33,33,33)', color: '#fff' }}
                date="2022.05"
                iconStyle={{ background: 'rgb(255,232,102)', color: '#fff' }}
            >
                <h3 className="vertical-timeline-element-title">{t("Section4.history.6.title" as any)}</h3>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                contentStyle={{ background: 'rgb(33,33,33)', color: '#fff' }}
                date="2022.04"
                iconStyle={{ background: 'rgb(255,232,102)', color: '#fff' }}
            >
                <h3 className="vertical-timeline-element-title">{t("Section4.history.7.title" as any)}</h3>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                contentStyle={{ background: 'rgb(33,33,33)', color: '#fff' }}
                iconStyle={{background: 'rgb(16, 204, 82)', color: '#fff'}}
                date="2022.04.29"
            >
                <h3 className="vertical-timeline-element-title">{t("Section4.history.8.title" as any)}</h3>
            </VerticalTimelineElement>
        </VerticalTimeline>
    );
};

function Section4() {
    const {t} = useTranslation("Home");
    return (
        <section
            id="about-section"
            style={{
                width: "100vw",
                minHeight: "1080px",
                backgroundColor: "#121212",
                padding: "100px 0 100px 0",
            }}>
            <div style={{margin: "100px 0px 100px 0px"}}>
                <h3 style={{color: "#ffffff", fontWeight: "bold", fontSize: "44px"}}>
                    {t("Section4.title" as any)}
                </h3>
            </div>
            <ResponsiveTimeline/>
        </section>
    );
}


const Team = () => {
    const { t } = useTranslation("Home");

    const teamMembers = [
        {
            id: 1,
            name: t("Section5.teams.0.name" as any),
            role: t("Section5.teams.0.role" as any),
            imageUrl: t("Section5.teams.0.imageUrl" as any),
        },

        {
            id: 2,
            name: t("Section5.teams.1.name" as any),
            role: t("Section5.teams.1.role" as any),
            imageUrl: t("Section5.teams.1.imageUrl" as any),
        },

        {
            id: 3,
            name: t("Section5.teams.2.name" as any),
            role: t("Section5.teams.2.role" as any),
            imageUrl: t("Section5.teams.2.imageUrl" as any),
        },
    ]

    return (
        <div
            style={{
                display: 'flex',
                flexWrap: "wrap"
            }}>
            {teamMembers.map((member) => (
                <div key={member.id} style={{textAlign: 'center'}}>
                    <img
                        src={member.imageUrl}
                        alt={member.name}
                        style={{
                            borderRadius: '50%',
                            width: '40%',
                            height: 'auto',
                            margin: "50px"
                        }}
                    />
                    <h1>{member.name}</h1>
                    <h2>{member.role}</h2>
                </div>
            ))}
        </div>
    )
}

function Section5() {
    const { t } = useTranslation("Home");

    return (
        <section
            style={{
                width: "100vw", margin: "auto", justifyItems: "center",
                backgroundColor: "#151515", padding: "100px 0"
            }}>
            <div>
                <h3 style={{color: "#01ff3c", fontWeight: "bold", fontSize: "44px"}}>
                    {t("Section5.title" as any)}
                </h3>
            </div>
            <Team/>
        </section>
    );
}


function Section6() {
    const { t } = useTranslation("Home");
    const handleEmailClick = () => {
        window.location.href = 'mailto:contact@joyfulturtles.com?subject=Contact%20Us&body=Hello,';
    };

    return (
        <section id="contact-section"
                 style={{
                     width: "100vw",
                     alignContent: "center",
                     height: isMobile ? "480px" : "720px",
                     backgroundColor: "#121212"
                 }}>
            <div>
                <h1>{t("Section6.title" as any)}</h1>
                <br/>
                <Button onClick={handleEmailClick}
                        variant="primary"
                        style={{
                            fontSize: "32px",
                            fontStyle: "bold",
                            background: "rgb(16,204,82)",
                            borderColor: "rgba(255,0,255,0)"}}>
                    {t("Section6.contact" as any)}
                </Button>
            </div>
        </section>
    );
}

function HomePage() {
    return (
        <div style={{justifyItems: "center"}}>
            <Section1/>
            <Section2/>
            <Section3/>
            <Section4/>
            <Section5/>
            <Section6/>
        </div>
    );
}


export default HomePage