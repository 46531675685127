import React from "react"
import { Navbar, Nav, Container} from "react-bootstrap";
import { isMobile} from 'react-device-detect';
import "../styles/Header.scss"


function HeaderMobile () {
    return (
        <>
            <header>
                <Navbar bg="light" expand="lg">
                    <Container>
                        <Navbar.Brand href="/">
                            <img src="/images/logo.png" alt="joyfulturtlse" width="128px"/>
                        </Navbar.Brand>
                        {/* 모바일에서 메뉴를 열고 닫을 수 있는 토글 버튼 */}
                        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{backgroundColor: "white"}}/>
                        <Navbar.Collapse id="basic-navbar-nav" >`
                            <Nav className="ms-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="#products-section">Products</Nav.Link>
                                <Nav.Link href="#solutions-section">Solutions</Nav.Link>
                                <Nav.Link href="#about-section">About us</Nav.Link>
                                <Nav.Link href="#contact-section">Contact us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </>
    );
}

function HeaderPC() {
    return (
        <header style={{marginBottom: "10px"}}>
            <Navbar bg="light" variant="light" expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <img src="/images/logo.png" alt="joyfulturtles" width="128"/>
                    </Navbar.Brand>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="#products-section">Products</Nav.Link>
                            <Nav.Link href="#solutions-section">Solutions</Nav.Link>
                            <Nav.Link href="#about-section">About us</Nav.Link>
                            <Nav.Link href="#contact-section">Contact us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

const Header = () => {
    return isMobile ? <HeaderMobile/> : <HeaderPC/>
}


export default Header